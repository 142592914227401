<template>
	<ZyroCard class="banner">
		<div class="banner__container">
			<div class="banner__content">
				<h3 class="z-h5 z-font-weight-light banner__content-title">
					{{ $t('siteSettings.general.bannerDomain.title') }}
				</h3>
				<p class="z-body">
					{{ $t('siteSettings.general.bannerDomain.text') }}
				</p>
			</div>
			<ZyroButton
				theme="primary"
				data-qa="sitesettings-generalsettings-btn-connectdomain"
				to="domain"
			>
				{{ $t('siteSettings.general.bannerDomain.btn') }}
			</ZyroButton>
			<ZyroSvg
				name="decoration-blue"
				class="banner__decoration banner__decoration--bottom-middle"
			/>
			<ZyroSvg
				name="decoration-purple"
				class="banner__decoration banner__decoration--top-right"
			/>
			<ZyroSvg
				name="decoration-dot"
				class="banner__decoration banner__decoration--bottom-right"
			/>
		</div>
	</ZyroCard>
</template>

<script>
import ZyroCard from '@/components/site-settings/components/ZyroCard.vue';

export default {
	components: { ZyroCard },
	props: {
		websiteId: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
.banner {
	position: relative;
	z-index: 2;

	@include mobile-view {
		padding: 34px 16px;
	}

	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include mobile-view {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__content {
		width: 75%;
		padding-right: 10px;

		@include mobile-view {
			width: 100%;
			padding-right: 0;
			margin-bottom: 24px;
		}

		&-title {
			@include mobile-view {
				margin-bottom: 24px;
			}
		}
	}

	&__decoration {
		position: absolute;
		z-index: -1;

		&--top-right {
			top: -20px;
			right: 0;

			@include mobile-view {
				top: -95px;
			}
		}

		&--bottom-middle {
			right: 40%;
			bottom: -10px;
			transform: translate(50%, 0%);

			@include mobile-view {
				right: 25%;
			}
		}

		&--bottom-right {
			right: 32%;
			bottom: -2px;
			transform: translate(50%, 0%);
		}
	}
}

</style>
