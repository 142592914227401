<template>
	<div>
		<ZyroTooltip
			content-position="absolute"
			:position="!!domain ? 'bottom' : 'rightTop'"
			:triangle="false"
		>
			<template #trigger>
				<ZyroButton
					theme="outline"
					icon="three-dots"
					title="More settings"
					data-qa="sitesettings-generalsettings-btn-moresettings"
				/>
			</template>
			<div>
				<ul class="control-tooltip">
					<li
						v-for="button in $options.settingsButtons"
						:key="`${button.icon}-button`"
						class="control-tooltip__button"
					>
						<ZyroButton
							v-qa="`sitesettings-generalsettings-btn-${button.text}`"
							theme="plain"
							:icon-left="button.icon"
							:title="button.title"
							@click="settingClickHandler(button.id)"
						>
							{{ button.text }}
						</ZyroButton>
					</li>
				</ul>
			</div>
		</ZyroTooltip>
		<SiteSettingsModal
			v-if="activeModal === 'rename'"
			:title="$t('siteSettings.general.seo.title.label')"
			:right-button-text="$t('common.saveChanges')"
			:is-right-button-disabled="$v.editTitle.$invalid || !$v.editTitle.$dirty"
			@close="activeModal = '', reset()"
			@left-button-click="activeModal = '', reset()"
			@right-button-click="updateTitle(), activeModal = '', reset()"
		>
			<ZyroFieldText
				:value="editTitle"
				:label="$t('siteSettings.general.seo.title.label')"
				label-theme="secondary"
				input-theme="secondary"
				data-qa="sitesettings-generalsettings-inputfield-rename"
				:placeholder="$t('siteSettings.general.seo.title.placeholder')"
				:bold="false"
				maxlength="50"
				@input="$v.editTitle.$touch(), editTitle = $event.target.value"
			/>
		</SiteSettingsModal>
		<SiteSettingsModal
			v-if="activeModal === 'duplicate'"
			:title="$t('common.copyWebsite')"
			:right-button-text="$t('common.copyWebsite')"
			:is-right-button-disabled="$v.duplicateTitle.$invalid"
			@close="activeModal = ''"
			@left-button-click="activeModal = ''"
			@right-button-click="duplicateSite"
		>
			<ZyroFieldText
				:value="duplicateTitle"
				:label="$t('siteSettings.general.seo.title.label')"
				label-theme="secondary"
				input-theme="secondary"
				data-qa="sitesettings-generalsettings-inputfield-makeacopy"
				:info-text="$t('siteSettings.general.seo.title.text')"
				:placeholder="$t('siteSettings.general.seo.title.placeholder')"
				:bold="false"
				maxlength="50"
				@input="$v.duplicateTitle.$touch(), duplicateTitle = $event.target.value"
			/>
		</SiteSettingsModal>
		<SiteSettingsModal
			v-if="activeModal === 'duplicated'"
			:title="$t('siteSettings.general.profile.modals.duplicated.title')"
			:right-button-text="$t('common.copyOpen')"
			@close="activeModal = ''"
			@left-button-click="activeModal = ''"
			@right-button-click="loadDuplicatedSite(), activeModal = ''"
		>
			<i18n
				path="siteSettings.general.profile.modals.duplicated.text"
				tag="p"
			>
				{{ websiteName }}
				<span class="z-font-weight-medium">
					{{ $t('common.mySites') }}
				</span>
			</i18n>
		</SiteSettingsModal>
		<SiteSettingsModal
			v-if="activeModal === 'delete'"
			:title="$t('common.deleteWebsite')"
			:right-button-text="$t('common.delete')"
			right-button-color="red"
			@close="activeModal = ''"
			@left-button-click="activeModal = ''"
			@right-button-click="deleteSite(), activeModal = ''"
		>
			{{ $t('siteSettings.general.profile.modals.delete') }}
		</SiteSettingsModal>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import {
	mapMutations,
	mapState,
} from 'vuex';

import {
	renameSite,
	duplicateSite as duplicateSiteApi,
	deleteSite as deleteSiteApi,
	selectSite,
} from '@/api/SitesApi';
import SiteSettingsModal from '@/components/site-settings/components/SiteSettingsModal.vue';
import {
	REDIRECT_TYPES,
	WWW_REDIRECT_PATHS,
} from '@/constants';
import i18n from '@/i18n/setup';
import { BUILDER_ROUTE } from '@/router';
import {
	mapActionsNotifications,
	NOTIFY,
} from '@/store/builder/notifications';
import { useRedirects } from '@/use/useRedirects';

const { REPLACE } = REDIRECT_TYPES;
const { SITES } = WWW_REDIRECT_PATHS;

const settingsButtons = [
	{
		id: 'edit',
		icon: 'edit',
		title: i18n.t('common.editWebsite'),
		text: i18n.t('common.edit'),
	},
	{
		id: 'rename',
		icon: 'rename',
		title: i18n.t('common.renameWebsite'),
		text: i18n.t('common.rename'),
	},
	{
		id: 'duplicate',
		icon: 'duplicate',
		title: i18n.t('common.makeCopyWebsite'),
		text: i18n.t('common.makeCopy'),
	},
	{
		id: 'delete',
		icon: 'trash',
		title: i18n.t('common.deleteWebsite'),
		text: i18n.t('common.delete'),
	},
];

export default {
	settingsButtons,
	components: { SiteSettingsModal },
	setup() {
		const { redirectToWWW } = useRedirects();

		return { redirectToWWW };
	},
	data() {
		return {
			activeModal: '',
			duplicateTitle: '',
			duplicatedSiteId: '',
			editTitle: '',
		};
	},
	validations: {
		editTitle: { required },
		duplicateTitle: { required },
	},
	computed: {
		...mapState([
			'website',
			'websiteId',
			'websiteName',
			'domain',
		]),
	},
	mounted() {
		this.reset();
	},
	methods: {
		...mapMutations(['setWebsiteName']),
		...mapActionsNotifications({ notify: NOTIFY }),
		updateTitle() {
			this.setWebsiteName(this.editTitle);
			renameSite(this.websiteId, this.editTitle);
		},
		settingClickHandler(id) {
			if (id === 'edit') {
				this.$router.push({ name: BUILDER_ROUTE });

				return;
			}

			this.activeModal = id;
		},
		async duplicateSite() {
			try {
				const { id } = await duplicateSiteApi(this.websiteId, this.duplicateTitle);

				this.duplicatedSiteId = id;
				this.activeModal = 'duplicated';
			} catch (error) {
				this.notify({
					origin: 'WebsiteControlPopup',
					message: `Could not duplicate the site. ${error.message || error}`,
				});
			}
		},
		async deleteSite() {
			try {
				await deleteSiteApi(this.websiteId);
				this.redirectToWWW({
					path: SITES,
					redirectType: REPLACE,
				});
			} catch (error) {
				this.notify({
					origin: 'WebsiteControlPopup',
					message: `Could not delete the site. ${error.message || error}`,
				});
			}
		},
		async loadDuplicatedSite() {
			try {
				await selectSite(this.duplicatedSiteId);
				this.$router.push({ name: BUILDER_ROUTE });
				this.$router.go();
			} catch (error) {
				this.notify({
					origin: 'WebsiteControlPopup',
					message: `Could not load the the duplicated site. ${error.message || error}`,
				});
			}
		},
		reset() {
			this.editTitle = this.websiteName;
			this.duplicateTitle = `${this.websiteName} ${this.$t('common.copy')}`;
			this.$v.$reset();
		},
	},
};
</script>

<style lang="scss" scoped>
.control-tooltip {
	list-style: none;

	//TODO add hover styles to ZyroButton component;
	&__button {
		transition: color 0.5s ease;

		&:not(:first-child) {
			padding-top: 8px;
		}

		&:hover {
			color: $secondary;
		}
	}
}
</style>
