<template>
	<div class="status">
		<ZyroSvg
			v-show="statusDone"
			name="check-round-green"
			class="status__icon"
		/>
		<span
			class="status__text z-body-small z-font-weight-bold"
		>
			{{ statusDone ? textDone : text }}
		</span>
	</div>
</template>
<script>
import i18n from '@/i18n/setup';

export default {
	props: {
		statusDone: {
			type: Boolean,
			default: false,
		},
		text: {
			type: String,
			default: i18n.t('common.update'),
		},
		textDone: {
			type: String,
			default: i18n.t('common.completed'),
		},
	},
};
</script>

<style lang="scss" scoped>

.status {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-right: 8px;

	&__icon {
		width: 12px;
		height: 12px;
		margin-right: 8px;
	}

	&__text {
		margin-bottom: 0;
		color: $dark;

		@include mobile-view {
			opacity: 0;
		}
	}
}

</style>
