<template>
	<ZyroCard class="profile">
		<!-- This part will be uncomented when conection with website preview is done; -->
		<!-- <div class="profile__website-container">
			<img
				src="@/assets/images/website-profile.jpg"
				alt=" "
				class="profile__website-img"
			>
		</div> -->
		<div class="profile__info-container">
			<div class="profile__info">
				<h2
					class="z-body z-body--strong"
					data-qa="sitesettings-generalsettings-title"
				>
					{{ title }}
				</h2>
				<a
					class="z-body profile__info-link"
					:href="url"
					title="Link to website"
					data-qa="sitesettings-generalsettings-link-siteurl"
					target="_blank"
				>
					{{ !!domain ? url : $t('siteSettings.general.profile.note') }}
				</a>
				<div class="profile__buttons-container">
					<ZyroButton
						v-if="!!domain"
						theme="outline"
						class="profile__button"
						data-qa="sitesettings-generalsettings-btn-viewlivewebsite"
						:href="url"
						target="_blank"
					>
						{{ $t('siteSettings.general.profile.btn') }}
					</ZyroButton>
					<WebsiteControlPopup :domain="domain" />
				</div>
			</div>
			<div class="profile__conections">
				<div class="profile__conections-domain">
					<div class="z-subheading-small z-subheading-small--spaced">
						{{ $t('siteSettings.general.profile.connections.domain.subtitle') }}:
						{{ isConnected ? $t('common.connected') : $t('common.notConnected') }}
					</div>
					<RouterLink
						class="z-link"
						to="domain"
						data-qa="sitesettings-generalsettings-link-connectdomain"
						:title="$t('siteSettings.general.profile.connections.domain.linkTitle')"
					>
						{{ $t('siteSettings.general.profile.connections.domain.linkText') }}
					</RouterLink>
				</div>
				<div class="profile__conections-plan">
					<div class="z-subheading-small z-subheading-small--spaced">
						{{ $t('siteSettings.general.profile.connections.plan.subtitle') }}:
						{{ plan }}
					</div>
					<RouterLink
						class="z-link"
						to="subscription"
						data-qa="sitesettings-generalsettings-link-viewsubscription"
						:title="$t('siteSettings.general.profile.connections.plan.linkTitle')"
					>
						{{ $t('siteSettings.general.profile.connections.plan.linkText') }}
					</RouterLink>
				</div>
			</div>
		</div>
	</ZyroCard>
</template>

<script>
import { mapState } from 'vuex';

import ZyroCard from '@/components/site-settings/components/ZyroCard.vue';
import WebsiteControlPopup from '@/components/site-settings/pages/general/components/WebsiteControlPopup.vue';
import i18n from '@/i18n/setup';

export default {
	components: {
		ZyroCard,
		WebsiteControlPopup,
	},
	props: {
		title: {
			type: String,
			default: i18n.t('siteSettings.general.profile.defaultTitle'),
		},
		url: {
			type: String,
			required: true,
		},
		isConnected: {
			type: Boolean,
			default: false,
		},
		plan: {
			type: String,
			default: i18n.t('siteSettings.general.profile.planType'),
		},
	},
	computed: mapState(['domain']),
};
</script>

<style lang="scss" scoped>
@import '@/components/site-settings/BuilderSiteSettings.scss';

.profile {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

	@include mobile-view {
		flex-direction: column;
	}

	&__website {
		&-container {
			width: 50%;
			margin-right: 40px;

			@include mobile-view {
				width: 100%;
				margin-right: 0;
			}
		}

		&-img {
			object-fit: fill;
			width: 100%;
			height: 100%;
		}
	}

	&__info {
		width: 50%;
		padding: 16px 8px 0 0;
		border-right: 1px solid $grey-300;

		@include mobile-view {
			width: 100%;
			margin-bottom: 24px;
			border-right: none;
		}

		&-container {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			width: 100%;

			@include mobile-view {
				flex-direction: column;
				width: 100%;
			}
		}

		&-link {
			display: inline-block;
			text-decoration: none;
			word-break: break-all;
		}
	}

	&__buttons-container {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		padding-top: 16px;
	}

	&__button {
		margin-right: 16px;
	}

	&__conections {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		width: 50%;
		padding-left: 64px;

		@include mobile-view {
			flex-direction: column;
			width: 100%;
			padding-left: 0;
		}

		&-domain {
			@include mobile-view {
				width: 100%;
				padding: 16px 0 24px;
				border-top: 1px solid $grey-300;
			}
		}

		&-plan {
			@include mobile-view {
				width: 100%;
				padding: 16px 0 0;
				border-top: 1px solid $grey-300;
			}
		}
	}
}
</style>
