<template>
	<section>
		<ZyroSettingsTitle>
			{{ $t('siteSettings.nav.general') }}
		</ZyroSettingsTitle>
		<Profile
			:title="websiteName || $t('siteSettings.general.profile.defaultTitle')"
			:url="siteUrl"
			:is-connected="!isFree && !!customDomain"
			:plan="isFree ? $t('siteSettings.general.profile.planType') : plan"
			:domain="domain"
		/>
		<BannerDomain
			v-if="!customDomain && hasActivePlan"
			:website-id="websiteId"
		/>
		<BannerInfo v-show="showBannerInfo" />
		<MetaImageUpload
			qa="favicon"
			name="favicon"
			:title="$t('common.favicon')"
			:info-text="$t('siteSettings.general.favicon.infoText')"
			:content="$t('common.favicon')"
			:file-types="[
				'ico', 'png', 'gif', 'jpeg', 'svg'
			]"
			:max-size="1e6"
			:upload-function="$options.uploadFavicon"
			:meta="website.meta"
			:is-premium="!!hasActivePlan"
			:upgrade-modal-title="$t('siteSettings.general.favicon.upgradeTitle')"
			:upgrade-modal-content="$t('siteSettings.general.favicon.upgradeText')"
			@set-website-meta="(key, value) => setWebsiteMeta({
				key,
				value
			})"
		>
			<template #default="metaProps">
				<SettingsBrowserPreview
					:title="websiteTitle || undefined"
					:url="siteUrl"
					:src="metaProps.src"
				/>
			</template>
		</MetaImageUpload>
		<MetaImageUpload
			qa="ogimage"
			name="ogImage"
			:title="$t('siteSettings.general.ogImage.heading')"
			:info-text="$t('siteSettings.general.ogImage.content.infoText')"
			:content="$t('siteSettings.general.ogImage.content.label')"
			:file-types="[
				'jpeg', 'png', 'jpg'
			]"
			:max-size="2e6"
			:upload-function="$options.uploadOgImage"
			:meta="website.meta"
			:is-premium="true"
			:upgrade-modal-title="$t('siteSettings.general.ogImage.upgradeTitle')"
			:upgrade-modal-content="$t('siteSettings.general.ogImage.upgradeText')"
			@set-website-meta="(key, value) => setWebsiteMeta({
				key,
				value
			})"
		>
			<template #default="metaProps">
				<OgImagePreview
					:title="websiteTitle || undefined"
					:url="siteUrl"
					:src="metaProps.src"
				/>
			</template>
		</MetaImageUpload>
	</section>
</template>

<script>
import {
	mapState,
	mapMutations,
	mapGetters,
} from 'vuex';

import {
	uploadFavicon,
	uploadOgImage,
} from '@/api/AssetsApi';
import ZyroSettingsTitle from '@/components/site-settings/components/ZyroSettingsTitle.vue';
import BannerDomain from '@/components/site-settings/pages/general/components/BannerDomain.vue';
import BannerInfo from '@/components/site-settings/pages/general/components/BannerInfo.vue';
import MetaImageUpload from '@/components/site-settings/pages/general/components/MetaImageUpload.vue';
import OgImagePreview from '@/components/site-settings/pages/general/components/OgImagePreview.vue';
import Profile from '@/components/site-settings/pages/general/components/Profile.vue';
import SettingsBrowserPreview from '@/components/site-settings/pages/general/components/SettingsBrowserPreview.vue';

export default {
	uploadFavicon,
	uploadOgImage,
	components: {
		BannerInfo,
		BannerDomain,
		ZyroSettingsTitle,
		Profile,
		MetaImageUpload,
		SettingsBrowserPreview,
		OgImagePreview,
	},
	data() {
		return { showBannerInfo: false };
	},
	computed: {
		...mapState([
			'website',
			'domain',
			'customDomain',
			'hasActivePlan',
			'websiteId',
			'websiteName',
		]),
		...mapState('subscription', ['plan']),
		...mapGetters('pages', ['defaultPages']),
		...mapGetters(['siteUrl']),
		websiteTitle() {
			const { meta } = this.website;

			return this.defaultPages[meta.homepageId]?.meta?.title || meta?.title || '';
		},
		hasMetaSet() {
			if (!this.defaultPages[this.website.meta.homepageId].meta) {
				return false;
			}

			const {
				title,
				description,
			} = this.defaultPages[this.website.meta.homepageId].meta;

			return !!title && !!description;
		},
		isFree() {
			return !this.hasActivePlan;
		},
	},
	mounted() {
		this.showBannerInfo = this.hasMetaSet;
	},
	methods: mapMutations(['setWebsiteMeta']),
};
</script>
