<template>
	<div>
		<div
			v-show="!showPreview"
			class="drag-area"
			:class="{ 'active' : dragging }"
			@dragenter="dragging = true"
			@dragover.prevent="dragging = true"
			@dragleave="dragging = false"
			@drop.prevent="handleFileUpload"
		>
			<form
				class="drag-area__form"
				@submit.prevent
			>
				<input
					:id="inputId"
					ref="input"
					type="file"
					name="image"
					class="drag-area__input"
					accept="image*"
					@change="handleFileUpload"
				>
				<label
					class="drag-area__label"
					:for="inputId"
					@click.prevent="handleClick"
				/>
				<div class="drag-area__not-active">
					<ZyroSvg
						name="image"
						class="drag-area__icon"
					/>
					<p class="z-body-small z-font-weight-light drag-area__text">
						{{ $t('siteSettings.imageUpload.text') }}
						{{ fileTypes.map(fileType => fileType.toUpperCase()).toString() }}
					</p>
					<ZyroButton
						v-qa="`metaimageblock-${qa}-btn-browsefiles`"
						class="drag-area__button"
						theme="outline"
						@click="$refs.input.click()"
					>
						{{ $t('siteSettings.imageUpload.btn') }}
					</ZyroButton>
				</div>
				<div class="drag-area__active">
					<ZyroSvg
						name="image-double"
					/>
					<p class="z-body-small z-font-weight-light drag-area__text">
						{{ $t('siteSettings.imageUpload.dropImgText') }}
					</p>
				</div>
			</form>
		</div>
		<div
			v-show="showPreview"
			class="drag__preview"
		>
			<div class="drag__preview-container">
				<img
					class="drag__preview-img"
					:src="previewSrc"
					:alt="$t('siteSettings.imageUpload.img.alt')"
				>
			</div>
			<div>
				<div class="drag__preview-text">
					{{ fileName }}
				</div>
				<div class="drag__buttons-container">
					<ZyroButton
						v-qa="`metaimageblock-${qa}-btn-replace`"
						theme="outline"
						class="drag-area__button"
						@click="$refs.input.click()"
					>
						{{ $t('common.replace') }}
					</ZyroButton>
					<ZyroButton
						v-qa="`metaimageblock-${qa}-btn-delete`"
						theme="outline"
						icon="trash"
						:title="$t('common.delete')"
						@click="clear()"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { nanoid } from 'nanoid';
import { mapActions } from 'vuex';

import {
	mapActionsNotifications,
	NOTIFY,
} from '@/store/builder/notifications';

export default {
	props: {
		fileTypes: {
			type: Array,
			default: () => ['jpeg, png, gif, svg'],
		},
		maxSize: {
			type: Number,
			default: 10e6, // 10Mb
		},
		initialPreviewSource: {
			type: String,
			default: '',
		},
		isPremium: {
			type: Boolean,
			default: true,
		},
		qa: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			dragging: false,
			showPreview: false,
			fileName: '',
			previewSrc: '',
			inputId: '',
			imgSize: '',
		};
	},
	watch: {
		previewSrc() {
			this.$emit('preview-source-change', this.previewSrc);
		},
	},
	created() {
		this.inputId = nanoid();
		if (this.initialPreviewSource) {
			this.previewSrc = this.initialPreviewSource;
			this.showPreview = true;
		}
	},
	methods: {
		...mapActions('subscription', ['autoConnectPlan']),
		...mapActionsNotifications({ notify: NOTIFY }),
		bytesToMegabytes(maxSize) {
			this.imgSize = (maxSize / 1000000);
		},
		async checkForPremium() {
			if (!this.isPremium) {
				await this.autoConnectPlan();
			}

			if (!this.isPremium) {
				this.$emit('has-no-premium');

				return false;
			}

			return true;
		},

		async handleClick() {
			const hasPremium = await this.checkForPremium();

			if (hasPremium) {
				this.$refs.input.click();
			}
		},

		async handleFileUpload(event) {
			const hasPremium = await this.checkForPremium();

			if (!hasPremium) {
				return;
			}

			const files = event?.dataTransfer?.files || event.target.files;

			if (this.validateImage(files.item(0))) {
				this.previewAndUploadImage(files.item(0));
				this.showPreview = true;
				this.dragging = false;
			}

			this.bytesToMegabytes(this.maxSize);
		},

		// TODO: create errors object in json
		validateImage(image) {
			const validTypes = this.fileTypes.map((fileType) => {
				const parsedType = (type) => `image/${type}`;

				if (fileType === 'ico') {
					return parsedType('x-icon');
				}

				return parsedType(fileType);
			});

			if (!validTypes.includes(image.type)) {
				this.notify({
					origin: 'ZyroImageUpload',
					message: this.$i18n.t('validate.fileType'),
				});

				return false;
			}

			if (image.size > this.maxSize) {
				this.notify({
					origin: 'ZyroImageUpload',
					message: this.$i18n.t('validate.fileMaxSize'),
				});

				return false;
			}

			return true;
		},

		previewAndUploadImage(image) {
			this.fileName = image.name;
			const reader = new FileReader();

			reader.addEventListener('load', (event) => {
				this.previewSrc = event.target.result;
				const formData = new FormData();

				formData.append('image', image);
				this.$emit('upload', formData);
			});
			reader.readAsDataURL(image);
		},

		clear(reset = false) {
			this.$refs.input.value = '';
			const isResetable = reset && !!this.initialPreviewSource;

			this.dragging = false;
			this.showPreview = isResetable;
			this.fileName = '';
			this.previewSrc = isResetable ? this.initialPreviewSource : '';
			this.$emit('upload', '');
		},
	},
};
</script>

<style lang="scss" scoped>
.drag {
	$this: &;

	&-area {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		min-height: 240px;
		padding: 20px 40px;
		background: $grey-100;
		border: 2px dashed $grey-200;
		border-radius: 8px;
		transition: all 0.2s ease-out;

		&:hover {
			border-color: $grey-600;
		}

		&.active {
			border-color: $grey-600;

			#{$this}-area__not-active {
				display: none;
			}

			#{$this}-area__active {
				display: block;
			}
		}

		&__not-active,
		&__active {
			text-align: center;
		}

		&__active {
			display: none;
		}

		&__form {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			padding-bottom: 18px;
		}

		&__label {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		&__input {
			visibility: hidden;
		}

		&__icon {
			margin-bottom: 16px;
		}

		&__text {
			margin-bottom: 24px;
			text-align: center;
		}

		&__button {
			margin-right: 8px;
		}
	}

	&__preview {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		&-img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

		&-text {
			padding-top: 13px;
			word-break: break-word;
		}

		&-container {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 144px;
			min-width: 144px;
			height: 144px;
			min-height: 144px;
			padding: 24px;
			margin-right: 22px;
			background: $grey-100;
			border: 1px solid $grey-200;
			border-radius: 8px;
		}
	}

	&__buttons {
		margin-right: 20px;

		&-container {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			margin-top: 20px;
		}
	}
}
</style>
