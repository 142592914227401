<template>
	<ZyroCard class="banner">
		<div class="banner__container">
			<ZyroSvg
				name="info"
				class="banner__icon"
			/>
			<div class="banner__content">
				<h3 class="z-body z-font-weight-bold">
					{{ $t('siteSettings.general.bannerInfo.title') }}
				</h3>
				<p class="z-body-small">
					{{ $t('siteSettings.general.bannerInfo.text') }}
					<RouterLink
						class="z-link"
						to="seo"
						:title="$t('siteSettings.general.bannerInfo.linkTitle')"
					>
						{{ $t('siteSettings.general.bannerInfo.linkText') }}.
					</RouterLink>
				</p>
			</div>
			<ZyroButton
				class="banner__button"
				theme="outline"
				to="seo"
			>
				{{ $t('siteSettings.general.bannerInfo.btn') }}
			</ZyroButton>
		</div>
	</ZyroCard>
</template>

<script>
import ZyroCard from '@/components/site-settings/components/ZyroCard.vue';

export default { components: { ZyroCard } };
</script>

<style lang="scss" scoped>
.banner {
	position: relative;

	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 30px;

		@include mobile-view {
			flex-direction: column;
			align-items: flex-start;
			padding-left: 22px;
		}
	}

	&__content {
		width: 75%;
		padding-right: 10px;

		@include mobile-view {
			width: 100%;
			padding-right: 0;
		}
	}

	&__icon {
		position: absolute;
		top: 27px;
		left: 32px;

		@include mobile-view {
			top: 19px;
			left: 15px;
		}
	}

	&__button {
		@include mobile-view {
			display: none;
		}
	}
}

</style>
