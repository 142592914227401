<template>
	<div class="og-preview">
		<p class="og-preview__title z-body-small">
			{{ $t('siteSettings.general.ogImage.content.label') }}
		</p>
		<Component
			:is="src ? 'img' : 'zyro-svg'"
			:src="src"
			name="preview-ogimage"
			alt="OG Image preview"
			class="og-preview__image"
		/>
		<p class="og-preview__user-title z-body-small">
			{{ title }}
		</p>
		<p class="og-preview__url z-body-small">
			{{ url }}
		</p>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default() {
				return this.$i18n.t('siteSettings.seo.textFields.title.label');
			},
		},
		url: {
			type: String,
			default: 'https://www.yourwebsitestitle.zyrosite.com',
		},
		src: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
.og-preview {
	height: 239px;
	padding: 13px 10px 10px 10px;
	background-color: $grey-100;

	&__title {
		margin-bottom: 14px;
	}

	&__image {
		width: 100%;
		max-height: 123px;
		object-fit: cover;
	}

	&__user-title {
		margin-bottom: 6px;
	}
}
</style>
