var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ZyroSettingsTitle',[_vm._v(" "+_vm._s(_vm.$t('siteSettings.nav.general'))+" ")]),_c('Profile',{attrs:{"title":_vm.websiteName || _vm.$t('siteSettings.general.profile.defaultTitle'),"url":_vm.siteUrl,"is-connected":!_vm.isFree && !!_vm.customDomain,"plan":_vm.isFree ? _vm.$t('siteSettings.general.profile.planType') : _vm.plan,"domain":_vm.domain}}),(!_vm.customDomain && _vm.hasActivePlan)?_c('BannerDomain',{attrs:{"website-id":_vm.websiteId}}):_vm._e(),_c('BannerInfo',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBannerInfo),expression:"showBannerInfo"}]}),_c('MetaImageUpload',{attrs:{"qa":"favicon","name":"favicon","title":_vm.$t('common.favicon'),"info-text":_vm.$t('siteSettings.general.favicon.infoText'),"content":_vm.$t('common.favicon'),"file-types":[
			'ico', 'png', 'gif', 'jpeg', 'svg'
		],"max-size":1e6,"upload-function":_vm.$options.uploadFavicon,"meta":_vm.website.meta,"is-premium":!!_vm.hasActivePlan,"upgrade-modal-title":_vm.$t('siteSettings.general.favicon.upgradeTitle'),"upgrade-modal-content":_vm.$t('siteSettings.general.favicon.upgradeText')},on:{"set-website-meta":function (key, value) { return _vm.setWebsiteMeta({
			key: key,
			value: value
		}); }},scopedSlots:_vm._u([{key:"default",fn:function(metaProps){return [_c('SettingsBrowserPreview',{attrs:{"title":_vm.websiteTitle || undefined,"url":_vm.siteUrl,"src":metaProps.src}})]}}])}),_c('MetaImageUpload',{attrs:{"qa":"ogimage","name":"ogImage","title":_vm.$t('siteSettings.general.ogImage.heading'),"info-text":_vm.$t('siteSettings.general.ogImage.content.infoText'),"content":_vm.$t('siteSettings.general.ogImage.content.label'),"file-types":[
			'jpeg', 'png', 'jpg'
		],"max-size":2e6,"upload-function":_vm.$options.uploadOgImage,"meta":_vm.website.meta,"is-premium":true,"upgrade-modal-title":_vm.$t('siteSettings.general.ogImage.upgradeTitle'),"upgrade-modal-content":_vm.$t('siteSettings.general.ogImage.upgradeText')},on:{"set-website-meta":function (key, value) { return _vm.setWebsiteMeta({
			key: key,
			value: value
		}); }},scopedSlots:_vm._u([{key:"default",fn:function(metaProps){return [_c('OgImagePreview',{attrs:{"title":_vm.websiteTitle || undefined,"url":_vm.siteUrl,"src":metaProps.src}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }