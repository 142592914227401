<template>
	<div class="browser-preview">
		<div class="browser-preview__header">
			<Component
				:is="src ? 'img' : 'zyro-svg'"
				class="browser-preview__header-favicon"
				:name="defaultIcon"
				:src="src"
			/>
			<div class="browser-preview__header-title">
				{{ title }}
			</div>
			<ZyroSvg
				class="browser-preview__header-background"
				:name="`preview-settings-browser${showDescription ? '-full' : ''}`"
			/>
			<div class="browser-preview__header-input">
				{{ url.toLowerCase() }}
			</div>
		</div>
		<div
			v-if="showDescription"
			class="browser-preview__content"
		>
			<div class="browser-preview__content-title">
				{{ title }}
			</div>
			<div class="browser-preview__content-link z-body-small">
				{{ url }}
			</div>
			<div class="browser-preview__content-text z-body-small">
				{{ description }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default() {
				return this.$t('siteSettings.seo.textFields.title.label');
			},
		},
		url: {
			type: String,
			default: 'https://www.mywebsite.com',
		},
		src: {
			type: String,
			default: null,
		},
		defaultIcon: {
			type: String,
			default: 'gray-rectangle',
		},
		description: {
			type: String,
			default: '',
		},
		showDescription: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
@mixin fade-text-end($width, $background-color) {
	width: $width;
	overflow: hidden;
	white-space: nowrap;

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 30%;
		height: 100%;
		content: '';
		background-image:
			(linear-gradient(
				to right,
				rgba($dark, 0) 0%,
				$background-color 100%
			));
	}
}

.browser-preview {
	max-width: 426px;
	margin-top: 10px;
	overflow: hidden;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.0445804);

	&__header {
		position: relative;
		overflow: hidden;
	}

	&__header-title {
		position: absolute;
		top: 18px;
		left: 86px;
		font-size: 9px;
		font-weight: 500;
		line-height: 1.56;
		letter-spacing: 0.3px;

		@include fade-text-end(90px, $light);
	}

	&__header-input {
		position: absolute;
		top: 40px;
		bottom: 14px;
		left: 100px;
		font-size: 9px;
		font-weight: 300;
		line-height: 2.44;
		color: #4a4a4a;
		letter-spacing: 0.45px;

		@include fade-text-end(300px, #F5F8FB);
	}

	&__header-favicon {
		position: absolute;
		top: 19px;
		left: 68px;
		max-width: 11px;
		max-height: 11px;
	}

	&__content {
		height: auto;
		padding: 16px;
	}

	&__content-text {
		margin-bottom: 3px;
		color: $grey-800;
	}

	&__content-link {
		color: #0d6e2d;
	}

	&__content-title {
		font-size: 18px;
		font-weight: bold;
		line-height: 1.78;
		color: #1a0dab;
	}
}
</style>
