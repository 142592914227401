<template>
	<div>
		<ZyroCollapsible
			v-qa="`sitesettings-generalsettings-card-${qa}`"
			:is-open="isOpen"
			@toggle="isOpen = !isOpen"
		>
			<template #heading>
				{{ title }}
			</template>
			<template #tag>
				<ZyroStatus
					v-if="isPremium"
					:status-done="!!textDone"
					:text-done="textDone"
				/>
				<ZyroPill v-else />
			</template>
			<template #content>
				<Transition
					name="slide-left"
					mode="out-in"
				>
					<span
						v-if="isLoading"
						class="meta-image__loader"
					>
						<ZyroLoader
							:style="{
								'--size': '42px',
								'--weight': '4px',
								'--border-color': 'var(--accent-two-light)',
								'--color': 'var(--light)'
							}"
						/>
					</span>
					<div
						v-else
						class="meta-image"
					>
						<div class="meta-image__upload">
							<ZyroLabel
								theme="secondary"
								:bold="false"
								:info-text="infoText"
							>
								{{ content }}
							</ZyroLabel>
							<ZyroImageUpload
								ref="metaImageUpload"
								:file-types="fileTypes"
								:is-premium="isPremium"
								:initial-preview-source="meta[name] || ''"
								:qa="qa"
								:max-size="maxSize"
								v-on="$listeners"
								@upload="file => uploadedFile = file"
								@has-no-premium="activeModalName = 'premium'"
								@preview-source-change="value => src = value"
							/>
						</div>
						<div class="meta-image__img-container">
							<ZyroLabel
								:bold="false"
								theme="secondary"
							>
								{{ $t('common.preview') }}
							</ZyroLabel>
							<slot :src="src" />
						</div>
					</div>
				</Transition>
			</template>
			<template
				v-if="!isLoading && uploadedFile !== null"
				#footer
			>
				<Transition name="fade">
					<ZyroButton
						v-qa="`sitesettings-generalsettings-card-${qa}-btn-cancel`"
						theme="plain"
						class="meta-image__footer-button"
						@click="activeModalName = 'confirm'"
					>
						{{ $t('common.cancel') }}
					</ZyroButton>
				</Transition>
				<ZyroButton
					v-qa="`sitesettings-generalsettings-card-${qa}-btn-save`"
					theme="primary"
					:disabled="uploadedFile === null || isLoading"
					@click="handleMetaImageUpload"
				>
					{{ $t('common.saveChanges') }}
				</ZyroButton>
			</template>
		</ZyroCollapsible>
		<SiteSettingsModal
			v-if="activeModalName === 'confirm'"
			:title="$t('common.cancelChanges')"
			:right-button-text="$t('common.discard')"
			:left-button-text="$t('common.continueEditing')"
			@left-button-click="activeModalName = ''"
			@right-button-click="activeModalName = '', cancel()"
		>
			{{ $t('siteSettings.common.unsavedChangesText') }}
		</SiteSettingsModal>
		<SiteSettingsModal
			v-if="activeModalName === 'premium'"
			:title="upgradeModalTitle"
			:right-button-text="$t('common.choosePlan')"
			@left-button-click="activeModalName = '', cancel()"
			@right-button-click="$router.push({ name: SUBSCRIPTION_SETTINGS_ROUTE })"
		>
			{{ upgradeModalContent }}
		</SiteSettingsModal>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import SiteSettingsModal from '@/components/site-settings/components/SiteSettingsModal.vue';
import ZyroCollapsible from '@/components/site-settings/components/ZyroCollapsible.vue';
import ZyroImageUpload from '@/components/site-settings/components/ZyroImageUpload.vue';
import ZyroStatus from '@/components/site-settings/components/ZyroStatus.vue';
import { SUBSCRIPTION_SETTINGS_ROUTE } from '@/router';
import {
	mapActionsNotifications,
	NOTIFY,
} from '@/store/builder/notifications';

export default {
	components: {
		ZyroCollapsible,
		ZyroStatus,
		ZyroImageUpload,
		SiteSettingsModal,
	},
	props: {
		title: {
			type: String,
			required: true,
		},
		infoText: {
			type: String,
			required: true,
		},
		content: {
			type: String,
			required: true,
		},
		fileTypes: {
			type: Array,
			required: true,
		},
		name: {
			type: String,
			required: true,
		},
		maxSize: {
			type: Number,
			required: true,
		},
		uploadFunction: {
			type: Function,
			required: true,
		},
		meta: {
			type: Object,
			required: true,
		},
		isPremium: {
			type: Boolean,
			required: true,
		},
		upgradeModalTitle: {
			type: String,
			required: true,
		},
		upgradeModalContent: {
			type: String,
			required: true,
		},
		qa: {
			type: String,
			default: null,
		},
	},
	setup() {
		return { SUBSCRIPTION_SETTINGS_ROUTE };
	},
	data() {
		return {
			uploadedFile: null,
			textDone: '',
			isLoading: false,
			activeModalName: '',
			src: null,
			isOpen: false,
		};
	},
	computed: mapState(['websiteId']),
	beforeMount() {
		this.textDone = this.meta[this.name] ? this.$t('common.saved') : '';
	},
	methods: {
		...mapActionsNotifications({ notify: NOTIFY }),
		async handleMetaImageUpload() {
			if (!this.uploadedFile) {
				this.setWebsiteMeta('');
			} else {
				try {
					this.isLoading = true;
					const { data } = await this.uploadFunction(this.uploadedFile);

					this.setWebsiteMeta(data.url);
				} catch (error) {
					const message = `Could not upload image. ${error?.response?.data?.msg || error}`;

					this.notify({
						origin: 'ZyroImageUpload',
						message,
					});
				}

				this.isLoading = false;
			}
		},
		setWebsiteMeta(value) {
			this.$emit('set-website-meta', this.name, value);
			this.textDone = this.$t('common.completed');
			this.uploadedFile = null;
		},
		cancel() {
			this.$refs.metaImageUpload.clear(true);
			this.uploadedFile = null;
		},
	},
};
</script>

<style lang="scss" scoped>
.meta-image {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

	@include mobile-view {
		flex-direction: column;
	}

	&__upload {
		width: 50%;
		margin-right: 40px;

		@include mobile-view {
			width: 100%;
			margin: 0 0 32px;
		}
	}

	&__loader {
		display: flex;
		min-height: 265px;

		@include mobile-view {
			min-height: 450px;
		}
	}

	&__img {
		width: 100%;

		&-container {
			width: 50%;

			@include mobile-view {
				width: 100%;
			}
		}
	}

	&__footer {
		&-button {
			margin-right: 32px;

			@include mobile-view {
				margin-right: 24px;
			}
		}
	}
}
</style>
